<template>
  <div class="row">
    <div class="col-12 col-lg-4 c g">
      <div class="card card-body text-white text-center">
        <img
          style="width: 100px"
          class="c"
          :src="require('@/assets/loading.svg')"
          alt=""
        />
        جاري الدخول...
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  created() {
    var g = this;
    $.post(window.api + "/client/join", {
      id: window.location.href.split("/j/")[1].split("/")[0],
    })
      .then(function (r) {
        if (!r?.response) {
          g.$router.push("/confirm");
        } else {
          localStorage.setItem(
            "last",
            `/?name=${encodeURI(r.response.name)}&phone=${encodeURI(
              r.response.name
            )}&service=${r.response.first_visit_from}&id=0`
          );
          window.location =
            `/?name=${encodeURI(r.response.name)}&phone=${encodeURI(
              r.response.phone
            )}&service=${r.response.first_visit_from}&id=0&jointo=` +
            window.location.pathname.split("/")[3];
        }
      })
      .catch(function () {
        g.$router.push("/confirm");
      });
  },
};
</script>

<style>
</style>